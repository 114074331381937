import React from "react";

const LeftToRightCard = ({heading, info, img, info1, info2}) => {

    return (
        <div className="row sm-reverse-wrap mx-2 mb-3">
        <div className="col-md-6 text-center">
            <img src={img} className="rounded-circle" width='340' height='340' alt="" />
        </div>
        <div className="col-md-6 d-flex align-items-center ps-0">
            <div className="right-content text-start ps-0">
                <h4>{heading}</h4>
                <p className="mt-2">{info}</p>
                <p className="mt-2">{info1}</p>
                <p className="mt-2">{info2}</p>
            </div>
        </div>
    </div>
    )
}

export default LeftToRightCard;