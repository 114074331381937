import React from "react";
import './App.css';
import Testimonials from './components/Testimonials';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';
import Header from './components/Header';
import HomeBanner from './components/HomeBanner';
import Contact from './components/Contact';
import Intro from './components/Intro';

const Videos = React.lazy(() => import("./components/Videos"));

function App() {
  return (
    <main>
        <Header />
        <HomeBanner />
        <Intro />
        <AboutMe />
        <React.Suspense fallback={<div>Loading Videos...</div>}>
          <Videos/>
        </React.Suspense>
        <Testimonials />
        <Contact />
        <Footer />
    </main>
  );
}

export default App;
