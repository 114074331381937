import React from "react";

const Testimonial = ({headingLeft, infoLeft, headingRight, infoRight}) => {

    return (
        <div id="testimonials" className="row sm-reverse-wrap mx-2 mb-3">
            <div className="col-md-6 d-flex align-items-center ps-0">
                <div className="right-content text-start ps-0">
                    <h4>{headingLeft}</h4>
                    <p className="mt-2">{infoLeft}</p>
                </div>
            </div>
            <div className="col-md-6 d-flex align-items-center ps-0">
                <div className="right-content text-start ps-0">
                    <h4>{headingRight}</h4>
                    <p className="mt-2">{infoRight}</p>
                </div>
            </div>
        </div>
    )
}

export default Testimonial;