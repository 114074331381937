import React from "react";

const Footer = () => {

    return (
        <footer className="bg-primary text-center pt-4 pb-3 text-light mt-5">
            <p>© 2024 All Rights Reserved. Quinn's Piano Studio</p>
        </footer>
    )
}

export default Footer