import React from "react";

const Intro = () => {

    return (
        <section id="Intro">
        <div className="discoverpianosection">
            <div className="container px-3">
                <div className="content">
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>Welcome to Quinn's Piano Studio</h2>
                        <h5>Expert Guidance, Personalized Approach: </h5>
                        <p className="mt-3">Located in Zionsville, Pennsylvania near Allentown, PA. Enjoy learning piano in a relaxed atmosphere in the comfort of your own home or with the convenience of online lessons! Please explore the site for more information and feel free to contact me with any questions or to add your name to the waiting list. </p>
                    </div>

                </div>

            </div>
        </div>
        </section>
    )
}

export default Intro;

