import React from "react";

const RightToLeftCard = ({heading, info, img, info1, info2, info3}) => {

    return (
        <div className="row mx-2 mb-3">
            <div className="col-md-6 d-flex align-items-center ps-0">
                <div className="right-content text-start ps-0">
                    <h4>{heading}</h4>
                    <p className="mt-2">{info}</p>
                    <p className="mt-2">{info1}</p>
                    <p className="mt-2">{info2}</p>
                    <p className="mt-2">{info3}</p>
                </div>
            </div>
            <div className="col-md-6 text-center">
                <img src={img} className="rounded-circle" width='331' height='442' alt="" />
            </div>
        </div>
    )
}

export default RightToLeftCard;