import React from "react";
import Testimonial from "./Testimonial";

const Testimonials = () => {

    return (
        <section id="testimonials">
        <div className="discoverpianosection">
            <div className="container px-3">

                <div className="content">
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>Testimonials </h2>
                        <h5>Read what others are saying about lessons at Quinn's Piano Studio </h5>
                        
                    </div>

                    <div className="body-content mt-5">
                        <Testimonial
                            headingLeft='Sue'
                            infoLeft="Quinn is the perfect package for a piano teacher. I am an adult piano student and not the most patient person but Quinn is encouraging, kind and supportive, allowing me to progress at my own rate of speed. She pays close attention to my needs and goals and is a great piano player with deep knowledge. I especially like working with her to choose and play songs that I enjoy most, and she never scolds when I confess my lack of practicing. I highly recommend Quinn for a teacher to someone at any level."
                            headingRight='Nancy'
                            infoRight=" My grandkids absolutely LOVE learning piano with Quinn! She's very enthusiastic and fun, but she sets expectations for them to achieve as well. She gives them musically challenging pieces, and even allows them to choose music they want to play, which means they also want to practice!! Their first recital happened only a few months after they started lessons, so she played duets with them which gave them great confidence... and didn't hurt our ears!! I highly recommend Quinn. She brings out the talents of her students!"
                        />
                        <Testimonial
                            headingLeft='Jess'
                            infoLeft="Quinn is a great piano teacher. She is super patient and explains everything so that you understand how to best play a piece. She was such a wonderful piano teacher when se was in Portland that I called her to teach me over FaceTime as well! Her teaching style made me enjoy learning piano and look forward to our lessons, both in person and over FaceTime."
                            headingRight='Alex'
                            infoRight="Quinn is the best piano teacher I know. She is kind and patient and thinks about each lesson for each student. She makes sure the child is engaged and makes sure to challenge them all the while still keeping the lesson at their level. There is no better teacher than someone who cares about their student. Quinn cares about each student and her results show it!"
                        />
                        <Testimonial
                            headingLeft='Chari'
                            infoLeft="Our son started piano lessons with Quinn at just four years old, when we weren't even sure if he'd have the attention span to sit through a 30 minute lesson. Shortly after, he performed a simple song at his first recital! Over the last year or so, piano has become an important and very enjoyable part of his life, and he is gaining a solid foundation in music... all while having loads of fun. We love Quinn's custom tailored lessons, and the happy, inquisitive way she approaches teaching. This attitude is contagious and makes him excited about learning music and eager to practice. The lessons are so much fun, yet structured and very progressive which enables him to make steady progress, and feel proud and accomplished for all of his hard work. Thanks to Quinn, music is his favorite!"
                            headingRight='Sophia'
                            infoRight="I love learning piano from Quinn!"
                        />
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Testimonials;