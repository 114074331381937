import React from "react";

const HomeBanner = () => {

    return (
        <section className="home-banner mb-2rem">
            <div className="inner-content d-flex justify-content-center align-items-center">
                <div className="content text-center">
                    
                    
                </div>
            </div>
        </section>
    )
}

export default HomeBanner;