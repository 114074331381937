import React from "react";
import RightToLeftCard from "./RightToLeftCard";
import LeftToRightCard from "./LeftToRightCard";

const AboutMe = () => {

    return (
        <section id="aboutMe">
        <div className="discoverpianosection">
            <div className="container px-3">
                <div className="content">
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>About Me </h2>
                        <p className="mt-3">Hi! My name is Quinn Hinojosa and I've been teaching piano since 2001. I started off teaching in Portland, Oregon and I am currently teaching out of Allentown, PA.
I love introducing the world of music to little ones as well as helping adult students realize they can make beautiful music...it's never too late!</p>
                    </div>
                    <div className="body-content mt-5">
                        <RightToLeftCard
                            heading='My goals as a teacher...'
                            img="./assets/images/quinn.jpg"
                            info1="1. I strive to make piano lessons fun, motivating and inspiring."
                            info2="2. I teach vital skills by using music the student enjoys. I strive to get to know my students interests and cater lessons to each individual."
                            info3="3. I continue my own learning and research to keep lessons fresh and to match the needs of each student."
                        />
                        <LeftToRightCard
                            heading="What are lesson like?"
                            img="./assets/images/piano-student8.jpg"
                            info="* In piano lessons you will learn to read music, compose, improvise, and even 'jam' with other musicians."
                            info1="* You will have the opportunity to explore all sorts of genres including classical, pop, jazz, and rock."
                            info2="* Music theory is taught through hands on activities instead of just workbooks. I use a variety of games and activities to make it fun and engaging."
                        />
                        <RightToLeftCard
                            heading='Where are lessons held?'
                            img="./assets/images/childQuinn.jpeg"
                            info="I teach virtually and in person. In person lessons are held in my home studio in Zionsville, PA or in the students home. (Some restrictions apply)"
                        />
                    </div>
                </div>

            </div>
        </div>
        </section>
    )
}

export default AboutMe;

